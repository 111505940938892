export function formatPrice(value, monetize) {
    if (monetize) {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0
        });

        return formatter.format(Number(value)).replace(/\s/g, '');
    }

    return value;
}

export function formatPriceToK(value) {
    // Controleer of het getal 1000 of meer is
    if (value >= 1000) {
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0 // Voorkomt decimalen voor duizendtallen
        });
        // Deel door 1000 en formatteer
        return formatter.format(value / 1000) + 'K';
    } else {
        // Voor waarden onder de 1000, gewoon formatteren zonder 'K'
        const formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0
        });
        return formatter.format(value);
    }
}
