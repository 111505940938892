<script setup>
    import {computed} from 'vue';
    import {formatPrice} from '@/Components/Composable/Monetize.js';

    const props = defineProps({
        current: {
            type: Number,
            required: true,
        },
        goal: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            default: 'number',
        },
        indicator: {
            type: [Boolean, String],
            default: false,
        },
        large: {
            type: Boolean,
            default: true,
        },
        labels: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
        },
        progressLabel: {
            type: String,
        },
        centerLabel: {
            type: Boolean,
            default: false,
        },
    })

    const start = computed(() => {
        return formatPrice(0, props.type === 'money')
    })

    const goal = computed(() => {
        return formatPrice(props.goal, props.type === 'money')
    })

    const progress = computed(() => {
        if (goal - start.value === 0) {
            return 0 // Avoid division by zero
        }

        return props.current / props.goal * 100
    })

    const progressRounded = computed(() => {
        return Math.round(progress.value);
    })

    const status = computed(() => {
        if (props.indicator === false) {
            return 'success'
        }

        if (typeof props.indicator === 'string') {
            return props.indicator;
        }

        if (progress.value > 100) {
            return 'danger';
        } else if (progress.value > 90) {
            return 'warning';
        } else {
            return 'success'
        }
    });
</script>

<template>
    <div class="flex items-center flex-col mt-1">
        <ul v-if="labels" class="flex items-center justify-between w-full text-xxs text-gray-400">
            <li>{{ start }}</li>
            <li>{{ goal }}</li>
        </ul>

        <div :class="[
            status === 'success' ? 'bg-success-300' :
            status === 'warning' ? 'bg-warning-300' :
            status === 'danger' ? 'bg-danger-300' : '',
            large ? 'h-10' : 'h-8',
            'flex w-full relative rounded-xl overflow-hidden items-center justify-center']">
            <div :style="{ width: `${progress}%` }" :class="[
                status === 'success' ? 'bg-gradient-to-r from-success-400 to-success-500' :
                status === 'warning' ? 'bg-warning-400' :
                status === 'danger' ? 'bg-danger-400' : '',
                'absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center h-full max-w-full transition ease-in-out'
            ]">
                <span class="text-sm font-bold text-white z-10" v-if="!centerLabel">{{ progressLabel ? progressLabel : `${progressRounded}%` }}</span>
            </div>

            <span class="text-sm font-bold text-white z-10" v-if="centerLabel">{{ progressLabel ? progressLabel : `${progressRounded}%` }}</span>
        </div>

        <ul v-if="labels" class="flex items-center justify-between w-full text-xxs text-gray-400">
            <li>0%</li>
            <li>50%</li>
            <li>100%</li>
        </ul>
    </div>
</template>
